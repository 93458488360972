import React, { useState } from "react";
import styled from "styled-components";
import SEO from "../components/SEO";
import { Home } from "../components/home";
import {
  ColoredSpan,
  StyledLink,
  StyledA,
  TitleH2Comp,
  CardContainer,
  CommonCard,
  CardH4,
  CardP,
  ReviewCardContainer,
  ReviewCard,
  ReviewLowerSection,
  ReviewCardLowerText,
  ImageSection,
  VisitLink,
  StoreInput,
  DomainInputTab,
  ImageSectionSegmently,
  ImageInputContainer,
  CommonArrowButton,
  ButtonArrow,
  ImageTitleH2,
  ContentLayout,
  CommonColorButton,
} from "../components/common";
import segmentlyImage from "../images/segmently-image.png";
import buttonArrow from "../images/arrow-white.svg";
import carousel1 from "../images/carousel-image-1.png";
import carousel2 from "../images/carousel-image-2.png";
import carousel3 from "../images/carousel-image-3.png";
import imageCampaigns from "../images/image-Campaigns.png";
import imageCustomiseTemplate from "../images/image-customise-template.png";
import imageDashboard from "../images/image-dashboard.png";
import imageLeads from "../images/image-leads.png";
import icon from "../images/icon-seg-1.svg";
import icon2 from "../images/icon-seg-2.svg";
import icon3 from "../images/icon-seg-3.svg";
import icon4 from "../images/icon-seg-4.svg";

const Segmently = () => {
  const [storeName, setStoreName] = useState("");
  return (
    <>
      <SEO
        title="Segmently"
        description="Shopify Apps That Make Your E-Commerce Journey Easier"
      />
      <Home
        segmently
        homeH1={`An automated way to attract store visitors and generate sales.`}
        homeText={`Design and run different attractive customized popups, banners and bars for your store visitors.`}
        imageArray={[
          { src: carousel1, alt: "video thumbline" },
          { src: carousel2, alt: "video thumbline" },
          { src: carousel3, alt: "video thumbline" },
        ]}
        buttonLink="https://apps.shopify.com/segmently"
        ButtonText="Add App"
        barColor="#4D7CFF"
      />

      <TitleH2Comp
        content={
          <>
            Features We Provide
            <ColoredSpan color="#4D7CFF"> From Segmently App</ColoredSpan>
          </>
        }
        barColor="#4D7CFF"
      />

      <ContentLayout
        image={
          <img
            src={imageCustomiseTemplate}
            alt="customise template segmently"
            width="100%"
          />
        }
        contentH3={`Customise Template`}
        contentP1={`Customize 20+ templates on your own without single coding. Design the templates with a few easy settings for both mobile and desktop to attract more visitors.`}
        imageAlign="1/1"
        textAlign="1/2"
        imageGridColumn="100%"
        textGridColumn="80%"
        gridColumns="35% 34%"
        justifyContent="center"
        new
      />

      <ContentLayout
        image={
          <img src={imageCampaigns} alt="campaigns segmently" width="100%" />
        }
        contentH3="Campaign Details"
        contentP1="Know the details of your popup campaigns with conversion count and rate. Get insights from the ongoing campaigns and plan for the better one next time."
        imageAlign="1/2"
        textAlign="1/1"
        imageGridColumn="100%"
        textGridColumn="80%"
        gridColumns="34% 38%"
        justifyContent="center"
        new
      />

      <ContentLayout
        image={<img src={imageLeads} alt="leads list segmently" width="100%" />}
        contentH3={`All Your Leads`}
        contentP1={`Generate leads from the store visitors with beautiful templates. Manage and know the details of the leads in a single place. Send custom emails and convert them into your loyal customer. `}
        imageAlign="1/1"
        textAlign="1/2"
        imageGridColumn="100%"
        textGridColumn="80%"
        gridColumns="35% 34%"
        justifyContent="center"
        new
      />

      <ContentLayout
        image={
          <img src={imageDashboard} alt="dashboard segmently" width="100%" />
        }
        contentH3="Dashboard"
        contentP1="Get a clear overview of your campaigns in the intuitive dashboard of Segmently. Know the overall impressions, impressions from web and mobile, conversion, conversion rate and many more."
        imageAlign="1/2"
        textAlign="1/1"
        imageGridColumn="100%"
        textGridColumn="80%"
        gridColumns="34% 38%"
        justifyContent="center"
        new
      />
      <TitleH2Comp
        content={
          <>
            Apps That Will Help You To
            <ColoredSpan color="#4D7CFF"> Boost Your Sales.</ColoredSpan>
          </>
        }
        barColor="#4D7CFF"
      />
      <CardContainer segmently>
        <CommonCard segmently>
          <img src={icon} alt="Easy & Powerful" />
          <CardH4>Easy & Powerful</CardH4>
          <CardP>Save your time and money using all-in-one app</CardP>
        </CommonCard>
        <CommonCard segmently>
          <img src={icon2} alt="Lightweight" />
          <CardH4>Lightweight</CardH4>
          <CardP>
            We don’t slow down your website. Segment.ly uses highly scalable
            cloud servers
          </CardP>
        </CommonCard>
        <CommonCard segmently>
          <img src={icon3} alt="No Coding Required" />
          <CardH4>No Coding Required</CardH4>
          <CardP>
            All settings are united in visual interface so you can easily setup
            all necessary parameters
          </CardP>
        </CommonCard>
        <CommonCard segmently>
          <img src={icon4} alt="20+ Templates" />
          <CardH4>20+ Templates</CardH4>
          <CardP>
            All settings are united in visual interface so you can easily setup
            all necessary parameters
          </CardP>
        </CommonCard>
      </CardContainer>

      <ImageSectionSegmently>
        <ImageTitleH2>
          We Touched The Life Of Merchants’ Custom Development
        </ImageTitleH2>
        <p style={{ color: `rgba(255, 255, 255, 0.7)` }}>
          Segmently is free to use as long as you want, includes everything to
          run your campaigns right now. Unlock premium features and go without
          our branding for only $9 per month
        </p>
        <ImageInputContainer>
          <div style={{ display: `flex` }}>
            <StoreInput
              type="text"
              name="store-domain"
              placeholder="Your Store Name"
              value={storeName}
              onChange={(e) => setStoreName(e.target.value)}
            />
            <DomainInputTab>.myshopify.com</DomainInputTab>
          </div>
          <CommonColorButton
            bgColor="#4D7CFF"
            hoverBg="#4773ee"
            onClick={() => {
              window.open(
                `https://apps.shopify.com/segmently?ref=segmently-${storeName}`
              );
            }}
          >
            Try It for Free!
          </CommonColorButton>
        </ImageInputContainer>
      </ImageSectionSegmently>

      <TitleH2Comp
        content={
          <>
            Here’s What
            <ColoredSpan color="#4D7CFF"> People Are Saying</ColoredSpan>
          </>
        }
        barColor="#4D7CFF"
      />
      <ReviewCardContainer>
        <div>
          <ReviewCard shadowColor="0px 23px 26px #99B3D229">
            <CardP>
              “Using the Facebook like campaign option with a discount code
              really increased our sales. Highly recommended!.”
            </CardP>
            <ReviewLowerSection>
              <ReviewCardLowerText>Core Trainer</ReviewCardLowerText>
              <ReviewCardLowerText date>July 7, 2021</ReviewCardLowerText>
            </ReviewLowerSection>
          </ReviewCard>
          <ReviewCard shadowColor="0px 23px 26px #99B3D229">
            <CardP>
              “Great App! The best thing in the app is the quick service from
              the support team 🙂 LOVE IT”
            </CardP>
            <ReviewLowerSection>
              <ReviewCardLowerText>Inspiring Wave</ReviewCardLowerText>
              <ReviewCardLowerText date>April 22, 2021</ReviewCardLowerText>
            </ReviewLowerSection>
          </ReviewCard>
        </div>
        <div>
          <ReviewCard shadowColor="0px 23px 26px #99B3D229">
            <CardP>
              “Amazing APP! Interface is simple, heaps of features and great
              straight forward flat monthly fee. Highly Recommended.”
            </CardP>
            <ReviewLowerSection>
              <ReviewCardLowerText>Endevz</ReviewCardLowerText>
              <ReviewCardLowerText date>June 10, 2021</ReviewCardLowerText>
            </ReviewLowerSection>
          </ReviewCard>
          <ReviewCard shadowColor="0px 23px 26px #99B3D229">
            <CardP>
              “The app did exactly what I needed. It is simple to use and
              creates a nice look on the page. I don’t like that I cannot remove
              the branding at the bottom, but that is minor. I did have a
              problem with the app and…”
            </CardP>
            <ReviewLowerSection>
              <ReviewCardLowerText>Blue Flamingo Studio</ReviewCardLowerText>
              <ReviewCardLowerText date>March 8, 2021</ReviewCardLowerText>
            </ReviewLowerSection>
          </ReviewCard>
        </div>
        <div>
          <ReviewCard shadowColor="0px 23px 26px #99B3D229">
            <CardP>“Great app. Olaf is always available for questions.”</CardP>
            <ReviewLowerSection>
              <ReviewCardLowerText>
                Bare Skin By Dr Bollmann
              </ReviewCardLowerText>
              <ReviewCardLowerText date>May 7, 2021</ReviewCardLowerText>
            </ReviewLowerSection>
          </ReviewCard>
          <ReviewCard shadowColor="0px 23px 26px #99B3D229">
            <CardP>
              “VERY basic. It doesn’t allow to customize the page, it’s very
              generic. It’s a cool concept, so I hope the app is further
              developed and have better features…”
            </CardP>
            <ReviewLowerSection>
              <ReviewCardLowerText>Kallie Shoes</ReviewCardLowerText>
              <ReviewCardLowerText date>April 22, 2021</ReviewCardLowerText>
            </ReviewLowerSection>
          </ReviewCard>
        </div>
      </ReviewCardContainer>
      <ImageSection>
        <ImageTitleH2>
          We Touched The Life Of{" "}
          <ColoredSpan color="#2D2D51"> 600+</ColoredSpan> Merchants’ Custom
          Development
        </ImageTitleH2>
        <VisitLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://apps.shopify.com/partners/appscom-io"
        >
          <CommonArrowButton>
            <div>Browse Apps</div>
            <ButtonArrow>
              <img
                src={buttonArrow}
                alt="arrow"
                style={{ marginLeft: `10px` }}
              />
            </ButtonArrow>
          </CommonArrowButton>
        </VisitLink>
      </ImageSection>
    </>
  );
};

export default Segmently;
